.CreateMainContainer>h2 {
    padding: 30px 0;
}

#firstChildDiv {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 30px;
    justify-content: space-between;
}

.inputDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inputDiv>label {
    margin-bottom: 10px;
    font-size: large;
}

.inputBox{
    width: 100%;
    height: 50px;
    font-weight: 500;
    font-size: large;
    padding: 0 10px;
}