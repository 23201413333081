
.childDivOne {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
}

.childDivOne>h3, .tableDiv > h2, .articlesTableDiv>h3 {
    color: #809275;
}

.childDivOne>h3>span {
    font-size: x-large;
    color: black;
}

.childDivTwo {
    display: flex;
    gap: 40px;
}

.tableDiv:nth-child(1) {
    flex: 3.5;
}

.tableDiv:nth-child(2) {
    flex: 6.5;
}

.tableDiv>h2, .articlesTableDiv>h3 {
    margin-bottom: 10px;
}

.listTable {
    border: 1px solid gray;
    border-collapse: collapse;
    width: 100%;
}

.listTable td, .listTable th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.articlesTableDiv {
    margin-bottom: 20px;
}