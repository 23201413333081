.CreateMainContainer>h2 {
    padding: 30px 0;
}

.inputDiv {
    display: flex;
    flex-direction: column;
}

.inputDiv>label {
    margin-bottom: 10px;
    font-size: large;
}

#form input {
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: larger;
}

#form select {
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: medium;
}

#firstChildDiv,
#nameNdPriceDiv {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    width: 100%;
}

#nameNdPriceDiv>div:nth-child(1) {
    flex: 8;
}

#nameNdPriceDiv>div:nth-child(2) {
    flex: 2;
    width: 150px;
}

#nameNdPriceDiv>div:nth-child(3) {
    width: 200px;
}

#nameNdPriceDiv>div:nth-child(4) {
    width: 150px;
}

#firstChildDiv>div:nth-child(1) {
    flex: 3.5;
}

#firstChildDiv>div:nth-child(2) {
    flex: 4;
}

#firstChildDiv>div:nth-child(3) {
    width: 25%;
}

#firstChildDiv>div:nth-child(3)>input {
    font-size: medium;
}

#secondChildDiv {
    display: flex;
    gap: 20px;
    width: 100%;
    margin: 20px 0;
}

#thirdChildDiv {
    display: flex;
    gap: 20px;
    width: 100%;
}

#thirdChildDiv>div>input {
    width: 100%;
}

#thirdChildDiv>div>select {
    width: 130px;
}

#secondChildDiv>div:nth-child(1) {
    width: 17%;
}

#secondChildDiv>div:nth-child(2) {
    width: 16%;
}

#secondChildDiv>div:nth-child(3) {
    width: 16%;
}

#secondChildDiv>div:nth-child(4) {
    width: 16%;
}

#secondChildDiv>div:nth-child(5) {
    width: 30%;
}

#form>button {
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
    background-color: #1f2937;
    color: white;
    font-size: larger;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
}

#fourthChildDiv {
    display: flex;
    gap: 20px;
    width: 100%;
    margin: 20px 0;
}

#fourthChildDiv > div {
    width: 100%;
}

#fourthChildDiv>div:last-child, #fourthChildDiv>div:nth-child(3) {
    width: 120%;
}