.MainContainer {
    padding: 8vh 0vw 5vh;
    width: 1250px;
    margin: auto;
}

#userListContainer {
    width: 100%;
    text-align: left;
    border-spacing: 0 10px;
}

#userListContainer th,
#userListContainer td {
    padding: 10px;
    font-size: larger;
    max-width: 16%;
}

#userListContainer tbody tr {
    width: 100%;
    background-color: #f3f4f6;
}

#userListHead {
    background-color: #627b8e;
    color: white;
}

#userListContainer tbody tr:hover {
    background-color: #ededed;
}

#userListContainer th:first-child,
#userListContainer tr>td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#userListContainer th:last-child,
#userListContainer tr>td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}