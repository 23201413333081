.MainContainer {
    padding: 8vh 0vw 5vh;
    width: 1240px;
    margin: auto;
    overflow-x: scroll;
}

#userListContainer {
    /* display: block; */
    /* width: 100%; */
    text-align: left;
    border-spacing: 0 10px;
}

#userListContainer th,
#userListContainer td {
    padding: 5px;
    font-size: small;
    max-width: 15%;
}

#userListContainer tbody tr {
    width: 100%;
    background-color: #f3f4f6;
}

#userListHead {
    background-color: #627b8e;
    color: white;
}

#userListContainer tbody tr:hover {
    background-color: #ededed;
}

.userCard p {
    background-color: #fee899;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    margin: 5px;
}

.userCard p>a {
    font-weight: 500;
}

#userListContainer th:first-child,
#userListContainer tr>td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#userListContainer th:last-child,
#userListContainer tr>td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#userListContainer tr>td:last-child {
    padding: 0 5px 0 0;
}

.userCard button {
    font-size: small;
    width: 100%;
    display: block;
    margin: auto;
    padding: 5px;
    border: none;
}

.userCard button:hover {
    cursor: pointer;
}

.userCard a {
    cursor: pointer;
    text-decoration: none;
    color: rgb(59, 59, 192);
    font-weight: bold;
}

.tableRemarkColumn {
    min-width: 300px;
}

.remarkInput {
    width: 100%;
    height: 40px;
    resize: none;
    padding: 2px;
}

.remarkInput:focus {
    outline: none;
}

.tableNameRow {
    width: 10%;
}

.tablePhoneNoColumn {
    width: 10%;
}

.tableSubscriptions {
    width: 10%;
}

.leadSelect {
    width: 100px;
    height: 40px;
}

.leadSelect:focus {
    outline: none;
}

.leadSelect > option {
    font-size: large;
}

#userListContainer tr>td:first-child {
    font-size: small;
    width: 5%;
}

.checkBoxes {
    width: 20px;
    height: 20px;
}

/* Responsiveness for small screen laptops */
@media only screen and (max-width : 1200px) {
    /* .MainContainer {
        width: 1000px;
    } */

    /* #tableDiv {
        width: 100%;
    }

    #userListContainer {
        display: block;
        width: 100%;
        overflow-y: auto;
    }

    .tableNameRow {
        min-width: 150px;
    }

    .tablePhoneNoColumn {
        min-width: 140px;
    }

    .tableSubscriptions {
        min-width: 140px;
    } */
}