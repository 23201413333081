.infoCard {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0 10px;
}

.infoCard>h4 {
    color: rgb(105, 105, 105);
    font-weight: 600;
}

.infoCard>h3 {
    font-weight: 600;
}

.accessLinks {
    background-color: antiquewhite;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    margin: 5px;
}

.userOrdersTableDiv {
    margin: 30px 0;
}

.userOrdersTableDiv>h2 {
    font-weight: 700;
    color: #809275;
}

.infoCard>input {
    height: 50px;
    resize: none;
    width: 90%;
    padding: 5px;
    caret-color: transparent;
}

.accessDiv {
    align-self: center;
    margin: 0;
    margin-right: 0;
    margin-left: auto;
    display: flex;
    gap: 20px;
}

.accessDiv>button {
    width: 150px;
    margin: 0;
}

.accessBtn {
    width: 200px;
    align-self: center;
    margin: 0;
    margin-right: 0;
    margin-left: auto;
}

.userActivityTable {
    margin-top: 10px;
    border: 1px solid gray;
    border-collapse: collapse;
    width: 100%;
}

.userActivityTable td,
.userActivityTable th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}