#runningListDiv {
    background-color: #f3f4f6;
    border-radius: 10px;
    margin: 20px 0;
}

.companyCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 2px solid white;
}

.companyCard > h3 {
    font-weight: 600;
}

.companyCard>a {
    text-decoration: none;
    color: black;
}

.editButton {
    width: 50px;
    height: 50px;
}

.name {
    font-weight: 600;
}

.inputDiv {
    display: flex;
    flex-direction: column;
}

.inputDiv>label {
    margin-bottom: 10px;
    font-size: large;
}

.inputDiv select {
    height: 40px;
    width: 200px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: medium;
}

.thInput {
    width: 100%;
    border: none;
    padding: 5px;
}

.thInput:focus {
    outline: none;
}

.tableHead {
    cursor: pointer;
}

.thWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.thWrapper h4 {
    color: white;
    font-size: small;
}

.thWrapper h4 span {
    color: white;
    font-size: x-small;
}

.thWrapper svg {
    vertical-align: middle;
    width: 25px;
    height: 15px;
}

.hoverArrowTh {
    opacity: 0.5;
}

.extraArrowDiv {
    width: 25px;
}

.thWrapper svg>path {
    color: white;
}