#bucketContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.bucketCard {
    text-align: center;
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
}

.bucketCard>h4 {
    margin: 10px 0 20px;
    font-weight: 500;
}

.cardBtns {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.editButton {
    width: 40px
}