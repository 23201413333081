.MainContainer {
    padding: 8vh 0vw 5vh;
    width: 1000px;
    margin: auto;
}

.headerDiv {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}

.headerDiv a {
    text-decoration: none;
}

.headerDiv > div {
    display:  flex; 
    gap: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}