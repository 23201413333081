#HomeMainContainer {
    display: flex;
    width: 1000px;
    gap: 50px;
}

#userInfoDiv {
    flex: 4;
}

#userInfoDiv>h2 {
    text-align: center;
    padding: 20px 0;
}

#userInfoDiv>form>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: large;
}

#userInfoDiv>form input {
    height: 40px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: large;
    padding: 10px;
    border: 1px solid black;
}

#userInfoDiv>form input[type="submit"] {
    width: 100px;
    background-color: #39566d;
    border: none;
    color: white;
    font-size: larger;
}

#userAnalytics {
    align-self: flex-start;
    flex: 6;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.accessCards {
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 10px;
}

.accessCards>h1 {
    color: #fb8135;
}

.accessCardsBtn {
    text-decoration: none;
    background-color: #39566d;
    width: 100px;
    text-align: center;
    padding: 5px;
    color: white;
    border-radius: 5px;
}

.accessCards>p {
    font-size: small;
}

/* Responsiveness for small laptop screens */
@media only screen and (max-width : 1050px) {
    #HomeMainContainer {
        margin-top: -20px;
    }

    #userAnalytics {
        gap: 15px;
    }
}