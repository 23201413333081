
#runningListDiv {
    background-color: #f3f4f6;
    border-radius: 10px;
    margin: 20px 0;
}

.courseCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 2px solid white;
}

.courseCard > a {
    text-decoration: none;
    color: black;
}

.dateDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editButton {
    width: 50px;
    height: 50px;
}