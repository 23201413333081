.CreateMainContainer>h2 {
    padding: 30px 0;
}

.inputDiv {
    display: flex;
    flex-direction: column;
}

.inputDiv>label {
    margin-bottom: 5px;
    font-size: large;
}

#form input {
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: larger;
}

#form select {
    height: 40px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: medium;
}

#firstChildDiv {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

#firstChildDiv>div:nth-child(1) {
    width: 35%;
}

#firstChildDiv>div:nth-child(2) {
    width: 30%;
}

#firstChildDiv>div:nth-child(3) {
    width: 20%;
}

#firstChildDiv>div:nth-child(4) {
    width: 15%;
}

#secondChildDiv {
    display: flex;
    gap: 20px;
    margin: 20px 0;
}

#secondChildDiv input, #thirdChildDiv input {
    margin: 0;
}

#secondChildDiv>div:nth-child(1) {
    width: 25%;
}

#secondChildDiv>div:nth-child(2) {
    width: 25%;
}

#secondChildDiv>div:nth-child(3) {
    width: 25%;
}

#secondChildDiv>div:nth-child(4) {
    width: 17%;
}
#secondChildDiv>div:nth-child(5) {
    width: 17%;
}

#thirdChildDiv>div:nth-child(1) {
    grid-area: desc;
}

#thirdChildDiv>div:nth-child(2) {
    grid-area: slots;
}

#thirdChildDiv>button {
    grid-area: submit;
    background-color: #1f2937;
    color: white;
    font-size: larger;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
}

#thirdChildDiv {
    display: grid;
    margin: 20px 0;
    gap: 20px;
    grid-template-areas:
        "desc desc desc desc slots slots"
        "desc desc desc desc submit submit";
}

#thirdChildDiv textarea {
    height: 100%;
    width: 100%;
    resize: vertical;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: large;
}