.revenueAnalytics {
    align-self: flex-start;
    flex: 5;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 50px;
}

.revenueCards {
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 20px;
}

.revenueCards>h1 {
    color: #fb8135;
}

.revenueCardsBtn {
    text-decoration: none;
    background-color: #39566d;
    width: 100px;
    text-align: center;
    padding: 5px;
    color: white;
    border-radius: 5px;
}

.latestOrderDiv>h2, .trialChartDiv > h2, .ledgerDiv> h2 {
    font-weight: 700;
    color: #809275;
}

.trialChartDiv {
    width: 100%;
    height: 400px;
    margin: 30px 0 60px;
}

.trialChartDiv > div {
    height: 100%;
    margin: 20px 0;
}

.csvRevenue {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 30px 0 50px;
}
