
#companyListContainer {
    width: 100%;
    text-align: left;
    border-spacing: 0 10px;
}

#companyListContainer th,
#companyListContainer td {
    padding: 10px;
    font-size: medium;
    max-width: 16%;
}

#companyListContainer tbody tr {
    width: 100%;
    background-color: #f3f4f6;
}

#companyListHead {
    background-color: #627b8e;
    color: white;
}

#companyListContainer th:first-child,
#companyListContainer tr>td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#companyListContainer th:last-child,
#companyListContainer tr>td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#companyListContainer th:nth-child(4),
#companyListContainer tr>td:nth-child(4) {
    max-width: 500px;
}

.editButton {
    width: 40px
}