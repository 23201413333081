.CreateMainContainer>h2 {
    padding: 30px 0;
}

#firstChildDiv {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 30px;
    justify-content: space-between;
}

.inputDiv {
    display: flex;
    flex-direction: column;
}

.inputDiv>label {
    margin-bottom: 10px;
    font-size: large;
}

.inputDiv input[type="file"] {
    height: 50px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: medium;
    width: 100%;
}

#tablesDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.tablesText {
    width: 100%;
    resize: vertical;
    height: 250px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: small;
}

button {
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
    background-color: #1f2937;
    color: white;
    font-size: larger;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
}