.InfoModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    padding: 20px;
    width: 500px;
    border-radius: 10px;
    text-align: center;
}

.spanDiv {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.spanDiv>h4 {
    font-weight: 600;
}

.spanDiv span {
    color: #444444;
}

#submitBtnDiv {
    display: flex;
    gap: 10px;
}

.selectAmt {
    display: block;
    height: 40px;
    width: 100%;
    margin: 20px auto;
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: center;
    font-size: larger;
}


.selectAmt:focus {
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
}

.submitBtn {
    margin: auto;
    background-color: #fb8135;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: medium;
    height: 40px;
    display: block;
}

.submitBtn:hover {
    background-color: #fe8d47;
}

#amountSelect {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

#amountSelect > input {
    width: 200px;
    font-size: large;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 10px;
}

#amountSelect>input:focus {
    outline: none;
}