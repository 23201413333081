.MainContainer {
    padding: 8vh 0vw 5vh;
    width: 1250px;
    margin: auto;
}

.batchesMainDiv {
    margin-bottom: 40px;
}

.batchesMainDiv>h2 {
    text-decoration: underline;
    color: #31486a;
    margin-bottom: 20px;
}

.batchesContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}


.batchCard {
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-align: center;
}

.batchCard>h2 {
    color: #fb8135;
}

.batchCard>h3 {
    margin: 20px 0;
}

.batchCard>h3>span {
    font-size: larger;
}

.batchCard>a {
    text-decoration: none;
    background-color: #39566d;
    width: 100px;
    text-align: center;
    padding: 5px;
    color: white;
    border-radius: 5px;
}

#analyticsDiv {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
}

#analyticsDiv > h3 > span {
    font-size: x-large;
    color: #fb8135;
}

/* Responsiveness for small screen laptops */
@media only screen and (max-width : 1200px) {
    .MainContainer {
        width: 1000px;
    }
}