#macroListContainer {
    width: 100%;
    text-align: left;
    border-spacing: 0 10px;
}

#macroListContainer th,
#macroListContainer td {
    padding: 20px;
    font-size: medium;
}

#macroListContainer tbody tr {
    width: 100%;
    background-color: #f3f4f6;
}

#macroListHead {
    background-color: #627b8e;
    color: white;
}

#macroListContainer tbody tr:hover {
    background-color: #ededed;
}

.thInput {
    width: 100%;
    height: 40px;
    border: none;
    padding: 5px 10px;
    font-size: medium;
}

.thInput:focus {
    outline: none;
}

#macroListContainer th:first-child,
#macroListContainer tr>td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#macroListContainer th:last-child,
#macroListContainer tr>td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 70%;
}