.MainContainer {
    padding: 8vh 0vw 5vh;
    width: 1240px;
    margin: auto;
}

#questionsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.questionsCard {
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
}

.questionText>p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.questionText li {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-left: 20px;
}

.questionText > ul, ol {
    margin: 15px 0 15px 0;
}

.cardButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.codex-editor__redactor {
    padding-bottom: 0;
    border: 1px solid green;
}