.textEditorDiv {
    height: 500px;
    /* adjust the height as needed */
}

.textEditor {
    height: 300px;
}

.inputDiv,
.textEditorDiv {
    margin-bottom: 30px;
}

.inputDiv>h3,
.textEditorDiv>h3,
#draftsContainer>h3 {
    margin-bottom: 10px;
    color: #809275;
}

.inputDiv>input {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: large;
    font-weight: 600;
}

.inputDiv>select {
    width: 300px;
    height: 40px;
    padding: 5px 10px;
    font-size: large;
    font-weight: 600;
}

.inputDiv>input:focus,
.inputDiv>select:focus {
    outline: none;
}

#mailContainer {
    display: flex;
    gap: 10px;
}

#draftsContainer {
    flex: 3;
    /* border: 1px solid #627b8e; */
    border-radius: 10px;
}

#createMailForm {
    flex: 7;
}

.draftItem {
    border: 1px solid #627b8e;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.draftItem:hover {
    background-color: rgb(247, 255, 255);
}

.draftItem>div {
    margin: 0;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.messageBox {
    width: 100%;
    height: 200px;
    margin: 10px 0 20px;
    resize: none;
    padding: 10px;
    font-size: large;
}