#NavbarMainContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 10vw;
    background-color: rgb(255, 249, 235);
    align-items: center;
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    height: 70px;
    z-index: 100;
}

#NavbarMainContainer > a {
    text-decoration: none;
    color: black;
}

#NavbarRightDiv {
    display: flex;
    gap: 25px;
}

#NavbarRightDiv > a {
    text-decoration: none;
    font-size: large;
    color: black;
}

#NavbarRightDiv>a:hover {
    color: #fb8135;
}

#sovrennLogo {
    width: 150px;
}

#NavbarRightDiv > button {
    margin: 0;
    width: 70px;
    font-size: small;
}