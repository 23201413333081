.CreateMainContainer>h2 {
    padding: 30px 0;
}

#firstChildDiv {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 30px;
    justify-content: space-between;
}

.inputDiv {
    flex: 5;
    display: flex;
    flex-direction: column;
}

.inputDiv>label {
    margin-bottom: 10px;
    font-size: large;
}
 
.inputDiv input, .inputDiv select {
    height: 100%;
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
    font-size: large;
}

.inputDiv textarea {
    resize: vertical;
    height: 200px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: x-large;
    font-weight: bold;
}